body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;

  /* Color definitions */
  --page-background-color: black;
  --background-color: #222;
  --foreground-color: white;
  --link-color: #66f;
  --border-color: #888;
  --error-color: tomato;
  --button-border-color: #999;

  color: var(--foreground-color);
  background-color: var(--page-background-color);
}

@media (prefers-color-scheme: light) {
  body {
    --page-background-color: white;
    --background-color: white;
    --foreground-color: black;
    --link-color: #888;
    --border-color: #888;
    --error-color: #888;
    --button-border-color: #999;
  }
}

body > #root > * {
  max-width: 1024px;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.llmsummary {
  white-space: pre-wrap;
  border: 1px solid yellow;
  color: yellow;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.logo {
  width: 192px;
  height: 192px;
  margin-bottom: 30px;
}

.actions {
  padding: 4px;
}

.actions button {
  margin-right: 4px;
}

a,
a:hover,
a:visited {
  text-decoration: none;
  color: var(--link-color);
  font-weight: 700;
}

img,
video {
  max-width: 100%;
}

article {
  overflow-y: hidden;
  max-height: 800px;
  border-bottom: 1px solid var(--foreground-color);
}

article button.expand {
  margin: 4px;
}

article.expanded {
  max-height: unset;
}

article img {
  width: auto;
  max-height: 150px;
}

article video {
  height: 550px;
  width: 100%;
}

article a.img-attachment:not(:first-child) {
  display: none;
}

article.expanded a.img-attachment:not(:first-child) {
  display: unset;
}

article section > table {
  width: 100%;
}

article .video-clip {
  border: none;
  width: 100%;
  height: 550px;
}

article.expanded img,
article.expanded video {
  display: unset;
  max-height: unset;
}

article h3 {
  margin-top: 0;
  margin-bottom: 6px;
}

article h6 {
  margin-top: 0;
  margin-bottom: 6px;
}

header {
  border-bottom: 1px solid var(--border-color);
}

button {
  cursor: pointer;
}

/* Visibility */
.hidden {
  visibility: hidden;
}

/* Display */
.db {
  display: block;
}

.di {
  display: inline;
}

/* Whitespace */
.pre-wrap {
  white-space: pre-wrap;
}

/* Position */
.sticky {
  top: 0;
  position: -webkit-sticky;
  position: sticky;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.bottom-right {
  bottom: 10px;
  right: 10px;
}

.bottom {
  bottom: 0px;
}

.left {
  left: 0px;
}

/* Width */
.width-100 {
  width: 100%;
}

/* Z-index */
.ontop {
  z-index: 1000;
}

/* Background */
.bg-gray {
  background-color: var(--background-color);
}

/* Foreground */
.error {
  color: var(--error-color);
}

.fg-color {
  color: var(--foreground-color);
}

/* Border */
.bd-btn {
  border: 2px solid var(--button-border-color);
}

.bd-radius-circle {
  border-radius: 100%;
}

/* Padding */
.pd-none {
  padding: 0;
}

.pd-small {
  padding: 2px;
}

.pd-medium {
  padding: 6px;
}

.pd-large {
  padding: 12px;
}

.pd-top-medium {
  padding-top: 6px;
}

.pd-bottom-medium {
  padding-bottom: 6px;
}

.pd-left-small {
  padding-left: 2px;
}

.pd-left-medium {
  padding-left: 6px;
}

.pd-left-large {
  padding-left: 12px;
}

.pd-right-small {
  padding-right: 2px;
}

.pd-bottom-xl {
  padding-bottom: 60px;
}

/* Margin */
.mg-none {
  margin: 0;
}

.mg-top-medium {
  margin-top: 6px;
}

.mg-bottom-small {
  margin-bottom: 2px;
}

.mg-bottom-medium {
  margin-bottom: 6px;
}

.mg-right-medium {
  margin-right: 6px;
}

.mg-left-medium {
  margin-left: 6px;
}

/* Flex */
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

/* Height */
.h-100 {
  height: 100vh;
}

/* Text */
.bold {
  font-weight: 700;
}

.normal {
  font-weight: 400;
}

.fnt-bigger {
  font-size: 1.4em;
}

/* Animations */
.spin {
  animation: spin-animation 1s linear infinite;
}

@keyframes spin-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Misc */
.list-style-none {
  list-style: none;
}

.btn-reset,
.btn-reset:active,
.btn-reset:focus {
  background-color: inherit;
  color: inherit;
  border: inherit;
  outline: inherit;
}
